.mainPostsDiv {
    margin: 30px 20%;
}

.postsTitleTip {
    margin-bottom: 80px;
    font-weight  : 700;
    font-size    : 32px;
    line-height  : 38px;
    color        : #363636;
}

.postsControlsContainer {
    display        : flex;
    justify-content: space-between;
}

.postsFilter {
    width      : 160px;
    margin-left: 15px;
}

.postCardsContainer {
    margin-bottom: 50px;
    margin-top   : 25px;
}

.generalPostInput {
    width        : 100%;
    display      : block;
    margin-bottom: 30px;
}

.postCardDiv {
    margin-bottom : 40px;
    padding       : 20px 30px 20px 60px;
    background    : #FAFAFA;
    border        : 1px solid #C1C1C1;
    border-radius : 10px;
    display       : flex;
    flex-direction: column;
}

.noBackground {
    margin    : 30px 20%;
    margin-top: 60px;
    background: none !important;
    border    : none !important;
}

.postActions {
    justify-self: flex-end;
    align-self  : flex-end;
}

.postCategory {
    margin-top    : -15px;
    font-weight   : 500;
    font-size     : 12px;
    line-height   : 14px;
    text-transform: uppercase;
    color         : #F32828;
    width         : 50%;
}

.postTitle {
    font-weight: 700;
    font-size  : 26px;
    line-height: 31px;
    color      : #363636;
    margin     : 5px 0px;
}

.postDate {
    font-weight   : 500;
    font-size     : 12px;
    line-height   : 14px;
    text-transform: uppercase;
    color         : #8D8D8D;
}

.postDate>a {
    color          : #8D8D8D;
    text-decoration: none;
}

.postContent {
    margin-top   : 20px;
    margin-bottom: 30px;
    font-weight  : 400;
    font-size    : 16px;
    line-height  : 19px;
    color        : #363636;
}

.editPostButton,
.deletePostButton {
    margin-right: 20px;
    width       : 120px;
}

.editPostSpan {
    cursor      : pointer;
    margin-right: 10px;
}

.deletePostSpan {
    cursor: pointer;
    color : #E03F35;
}

.viewPostBtn {
    width           : 100px;
    color           : white;
    background-color: black !important;
}

.postsActionButtonsContainer {
    display      : flex;
    margin-bottom: 30px;
}

.postImage {
    width        : 100%;
    margin-top   : 50px;
    margin-bottom: 40px;
}

.smallPostImage {
    max-width    : 100%;
    max-height   : 350px;
    margin-top   : 20px;
    margin-bottom: 40px;
}

.postBackButton {
    margin-bottom: 20px;
    font-weight  : 500;
    font-size    : 18px;
    color        : #000000;
    cursor       : pointer;
    width        : max-content;
    display      : flex;
}

.postBackText {
    margin-left: 12px;
}

.contentEditorContainer {
    /* min-height: 350px; */
    border: 1px solid #8D8D8D;
    cursor: text;
}

@media (max-width: 1000px) {
    .mainPostsDiv {
        margin: 30px;
    }

    .postsControlsContainer {
        justify-content: flex-start;
        flex-direction : column;
    }

    .postsTitleTip {
        margin-bottom: 40px;
    }

    .postCardDiv {
        padding: 20px;
    }

    .noBackground {
        margin: 0px;
    }
}