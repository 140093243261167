.row-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-around;
  background-color: #fff;
}
.alt-row-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-around;
  background-color:#ECECEC;
}
.row-container01 {
  width: 50px;
  display: flex;
  align-items: center;
  border:1px solid #000;
  border: 1px solid #000;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.row-text {
  color: #D12229;
}
.row-text01 {
  color: #D12229;
}
.row-text02 {
  color: #D12229;
}
.row-text03 {
  color: #D12229;
}
.row-container02 {
  width: 80px;
  display: flex;
  align-items: flex-start;
  border:1px solid #000;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.row-text04 {
  font-size: 20px;
  font-weight: bold;
  align-self: center;
}
.row-container03 {
  width: 140px;
  display: flex;
  align-items: center;
  border:1px solid #000;
  border-width: 0px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.row-text05 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
}
.row-text06 {
  font-size: 20px;
}
.row-container04 {
  flex: 1;
  display: flex;
  align-items: center;
  border:1px solid #000;
  border-width: 0px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.row-container05 {
  width: 150px;
  display: flex;
  align-items: center;
  border:1px solid #000;
  border-width: 0px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.row-container06 {
  width: 60px;
  display: flex;
  align-items: center;
  border:1px solid #000;
  border-width: 0px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.row-container07 {
  width: 150px;
  display: flex;
  align-items: center;
  border:1px solid #000;
  border-width: 0px;
  flex-direction: column;
  /* background-color: #fff; */
  border-top-width: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.row-container08 {
  width: 100px;
  display: flex;
  align-items: center;
  border:1px solid #000;
  border: 1px solid #000;
  flex-direction: column;
  /* background-color: #fff; */
  border-top-width: 0px;
}
.row-container09 {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  /* background-color: #fff; */
}
.row-container10 {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  border:1px solid #000;
  border: 1px solid #000;
  flex-direction: row;
  /* background-color: #fff; */
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
