.action-btn {
    padding      : 5px 15px;
    border-radius: 25px;
    border       : 1px solid #e5e5e5;
    margin-right : 10px;
    display      : inline-block;
    margin       : 0 0 5px 10px;
}

.action-btn:hover {
    cursor: pointer;
}

/* .addMemberButton {
    margin: 40px 0 40px 0;
} */

.addMemTitle1 {
    font-weight: 700;
    font-size  : 16px;
    line-height: 19px;
    color      : #363636;
}

.addMemTitle2 {
    font-weight: 400;
    font-size  : 16px;
    line-height: 29px;
    color      : #929292;
}

.editMemModalBody{
    height: 85vh;
    overflow-y: auto;
}

.editMemModal>div {
    width   : 80%;
    position: fixed;
    left    : 10%;
    top     : 30px;
}

.userContainerPc {
    display      : flex;
    margin-bottom: 30px;
    /* font-family  : 'SF Pro Text'; */
    font-style   : normal;
    font-size    : 16px;
    line-height  : 19px;
    padding-bottom: 12px;
    border-bottom: 1px solid #E5E5E5;;
}

.profile-avatar {
    height       : 60px;
    width        : 60px;
    border-radius: 50%;
    object-fit   : cover;
    margin : -5px 10px 0 0;
}

.nameContainer {
    margin-right: 10px;
    width       : 200px;
}

.companyContainer {
    margin-right: 20px;
    width       : 250px;
}

.statusContainer {
    margin-right: 40px;
    width       : 50px;
}

.green-state{
    color: #28A745;
}

.red-state{
    color: #E03F35;
}

.otherInfoContainer{
    margin-right: 20px;
    width       : 100px;
}

.remove-mem-btn {
    width: 100px;
    height: 30px;
    color: #E03F35;
    border-radius: 25px;
    border       : 1px solid #e5e5e5;
    margin  : -5px 0 0 20px;
    display      : flex;
    justify-content: center;
    align-items: center;
}

.remove-mem-btn:hover {
    cursor: pointer;
}

.remove-mem-btn>svg{
    margin: 2px 5px 0 0;
}

.topGroupActionsContainer{
    display: flex;
    justify-content: space-between;
    margin: 10px 0 40px 0;
}

.filterLabel{
    width: 40%;
}

.filterInput{
    width: 85%;
    margin-left: 5px;
}

.userContainerMobile{
    display: none;
}

@media (max-width: 768px){
    .topGroupActionsContainer {
        flex-direction: column;
        justify-content: flex-start;
    }

    .filterLabel{
        width: 100%;
    }

    .addMemberButton{
        width: 150px;
        margin-bottom: 20px;
    }
    
    .userContainerPc{
        display: none;
    }

    .userContainerMobile{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .mobileRow{
        display: flex;
        margin-bottom: 20px;
    }

    .remove-mem-btn{
        width: 40px;
    }

    .remove-mem-btn>svg{
        margin-right: 0px;
    }
}