.wholeContainer {
    width          : 100%;
    height         : 100%;
    top            : 0;
    left           : 0;
    z-index        : 1100;
    position       : fixed;
    display        : flex;
    justify-content: center;
    align-items    : center;
    text-align     : center;
}

.spinnerFullScreen {
    opacity   : 0.6;
    background: #000;
    width     : 100%;
    height    : 100%;
    z-index   : 1200;
    top       : 0;
    left      : 0;
    position  : fixed;
}

.loadingSpinner {
    font-size       : 3rem;
    border          : .3em solid #dc3545;
    border-top-color: rgba(255, 255, 255, 0.4);
    border-radius   : 50%;
    width           : 2em;
    height          : 2em;
    animation       : loadingSpinner 1s linear infinite;
    z-index         : 1300;
}

@keyframes loadingSpinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}