#members-modal .card {
    padding: 15px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    margin-bottom: 15px;
}
#members-modal .nav {
    border-radius: 6px;
    background: #F0F0F0;
}
#members-modal .nav-link {
    color: #8C8C8C;
    text-align: center;
}
#members-modal .nav-link:hover {
    cursor: pointer;
}
#members-modal .nav-link.active {
    background: #48494B;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    border-bottom: 0;
    color: #ffffff;
}
#members-modal .media {
    margin-bottom: 15px;
}
.tab-buttons {
    width: 50%;
}
.btn-basic.bordered {
    border: 1px solid #E5E5E5;
}