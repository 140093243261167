.awardsFullContainer {
    display        : flex;
    justify-content: center;
    padding        : 25px;
}

.tabsButtonsContainer {
    display        : flex;
    justify-content: center;
    margin-bottom  : 30px;
}

.tabButton {
    cursor       : pointer;
    padding      : 5px 10px;
    width        : 127px;
    height       : 35px;
    border-radius: 10px 0px 0px 10px;
    text-align   : center;
}

.activeTabButton {
    color     : #FFFFFF;
    background: #000000;
}

.inActiveTabButton {
    color : #8C8C8C;
    border: 1px solid #8C8C8C;
}

.leftBorderRadius {
    border-top-right-radius   : 0px;
    border-bottom-right-radius: 0px;
    border-right              : none;
}

.rightBorderRadius {
    border-top-left-radius   : 0px;
    border-bottom-left-radius: 0px;
    border-left              : none;
}

.noBorderRadius {
    border-radius: 0px;
    border-left  : none;
    border-right : none;
}

.containerWithScroll {
    height     : 500px;
    /* padding    : 20px; */
    display    : inline-block;
    /* overflow: scroll; */
    overflow-x : auto;
    overflow-y : auto;
    align-self : center;
}

.pointsCalcTip {
    margin-top : 40px;
    font-weight: 400;
    font-size  : 16px;
    line-height: 29px;
    color      : #808080;
    width      : 60%;
    text-align : center;
    align-self : center;
}

.crownImage {
    margin: 0 0 10px 0;
}

.rankNum {
    font-weight: 700;
    font-size  : 20px;
    line-height: 24px;
    display    : flex;
    align-items: center;
    padding    : 0 20px 10px 10px;
}

.pointsContainer {
    margin-left: 10px;
}

.pointsContainerFirst {
    font-weight: 700;
    font-size  : 24px;
    line-height: 24px;
    color      : #D12229;
}

.yearFilter {
    width        : 100px;
    height       : 35px;
    border       : 1px solid #BDBDBD;
    border-radius: 5px;
    font-weight  : 400;
    font-size    : 20px;
    line-height  : 25px;
    color        : #8C8C8C;
    padding-left : 12px;
}

.usersDropdown {
    width        : 300px;
    height       : 35px;
    border       : 1px solid black;
    border-radius: 5px;
    font-weight  : 400;
    font-size    : 20px;
    line-height  : 25px;
    margin-right : 20px;
}

.eventScoreCard {
    width        : 720px;
    background   : #FAFAFA;
    margin-bottom: 40px;
    display      : block;
    padding      : 0 20px;
}

.plusOrMinus {
    position : relative;
    left     : 95%;
    top      : 5px;
    font-size: 32px;
    cursor   : pointer;
}

.isBocEvent {
    border       : 1px solid #000000;
    border-radius: 5px;
    width        : 100px;
    text-align   : center;
}

.theName {
    font-weight: 700;
    font-size  : 26px;
    line-height: 31px;
}

.goldBorder {
    border: 4px solid #E5C100;
}

.silverBorder {
    border: 4px solid #D0D0D0;
}

.bronzeBorder {
    border: 4px solid #AC7727;
}

.aa11 {
    width       : 150px;
    padding-left: 20px;
}

.addNewHoleInOneButtonContainer {
    margin         : 0 30px 20px 0;
    display        : flex;
    justify-content: flex-end;
}

.holeInOneModalInput {
    width        : 100%;
    display      : block;
    margin-bottom: 30px;
}

.holeInOneScoresContainer {
    /* background-color: #E5C100; */
}

.holeInOneScoresContainer>table>tr>th {
    padding         : 10px 20px;
    background-color: #E8E8E8;
}

.holeInOneScoresContainer>table>tr>td {
    padding: 10px 20px;
}

.scoreUserProfileLink {
    color          : black;
    text-decoration: none;
}

.holeInOneMobileCard {
    margin        : 10px;
    padding       : 15px;
    border        : 2px solid #E8E8E8;
    border-radius : 10px;
    display       : flex;
    flex-direction: column;
}

.tabMiddleButton {
    cursor       : pointer;
    padding      : 5px 10px;
    width        : 127px;
    height       : 35px;
    text-align   : center;
}

.tabRightButton {
    cursor       : pointer;
    padding      : 5px 10px;
    width        : 127px;
    height       : 35px;
    border-radius: 0px 10px 10px 0px;
    text-align   : center;
}

@media (max-width: 620px) {
    .containerWithScroll {
        /* padding: 0px; */
        height: 70vh;
    }

    .eventScoreCard {
        margin : 0;
        padding: 0;
        width  : auto;
    }

    .plusOrMinus {
        left: 90%;
    }

    .aa11 {
        margin-top: 10px;
    }
}