.buttons-divider {
    margin-right: 10px;
    border-left : 1px solid #E5E5E5;
    height      : 20px;
}

.membershipStatus {
    text-transform: capitalize;
    font-weight   : 600;
}

.membershipStatus.Active {
    color: #28A745;
}

.membershipStatus.Inactive {
    color: #F32828;
}

/* .table {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #EBEBEB;
    padding-bottom: 15px;
    padding-top: 15px;
    align-items: flex-start;
    
}

.tableSectionWrapper{ 
    display: flex;
    flex-direction: row;
    width: max-content
}

.tableSection{ 
    display: flex;
    flex-direction: row;
    width: max-content;
}
.tableSectionMobile{ 

    width: max-content;
}

.userlist-table{
    height: calc(100vh - 170px);
    overflow: scroll;
} */

/* NEW STYLE */
#userList .row {
    margin       : 0;
    border-bottom: 1px solid #E5E5E5;
}

#userList .col {
    padding: 15px;
}

#userList .label {
    margin-left : 0;
    margin-right: 5%;
}

.action-web .label:not(.visible-mobile) {
    display     : inline-block;
    margin-right: 20px;
}

.profile-avatar {
    height       : 60px;
    width        : 60px;
    border-radius: 50%;
    object-fit   : cover;
}

.web-action-button {
    margin-top: 15px;
    display   : none;
}

.web-action-button .action-btn {
    padding      : 5px 15px;
    border-radius: 25px;
    border       : 1px solid #E5E5E5;
    margin-right : 10px;
    display      : inline-block;
    margin-bottom: 5px;
}

.web-action-button .action-btn:hover {
    cursor: pointer;
}

.row:hover .web-action-button {
    display: block;
}

.text-red {
    color: #E03F35;
}

.visible-mobile {
    display: none;
}

.expiry-date {
    width: 82px;
}

@media screen and (max-width: 426px) {

    /* border members info MembersModal */
    .web-action-button {
        display: none !important;
    }

    .border-members-modal {
        border        : 1px solid lightgrey;
        border-radius : 10px;
        padding       : 5px;
        margin        : 10px;
        display       : flex;
        flex-direction: column;
        width         : 95%;
    }

    #userList .row {
        display: block;
    }

    #userList .col:first-child {
        padding-bottom: 0;
    }

    .hidden-mobile {
        display: none;
    }

    .visible-mobile {
        display: block;
    }

    #userList .label:not(.hidden-mobile),
    .expiry-date:not(.hidden-mobile) {
        width  : 50%;
        margin : 0;
        display: inline-block;
    }
}

@media (max-width: 600px) {
    .userlist-table {
        height: calc(100vh - 190px);
    }
}

@media screen and (max-width: 999px) {

    .web-action-button{
        display: none !important;
    }
    .action-tablet {
        display: block;
    }

    .action-web,
    .action-tablet {
        padding-left : 15px;
        padding-right: 15px;
    }

    .action-web {
        display: none;
    }

    #userList .row {
        border-bottom: 0;
    }

    #userList .row:last-child {
        border-bottom : 1px solid #E5E5E5;
        padding-bottom: 10px;
    }

    .buttons-divider {
        padding-top   : 5px;
        padding-bottom: 5px;
    }

    .invoiceModalButton {
        margin-left: 0px;
    }

    /* .membershipStatus{ 
        margin-left: 20px;
        text-transform: capitalize;
        font-weight: bold;
    } */
    .table {
        /* display: block;
        grid-template-rows: 1fr;
        grid-template-columns: auto;
        grid-gap: 24px;
        -webkit-align-items: center;
        align-items: center;
        border-bottom: 2px solid #EBEBEB;
        padding-bottom: 30px;
        margin-bottom: 15px; */
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: flex-start;

    }

    .tableSection {
        padding: 5px;
    }

    .tableSectionWrapper {
        display       : flex;
        flex-direction: column;
        width         : max-content
    }

}

@media screen and (min-width: 1000px) {
    .action-tablet {
        display: none !important;
    }
}

.row-wrapper{
    margin-bottom: 20px;
}

.media{
    display: flex;
    flex-direction: column;
}

.media-row{
    display: flex;
    flex-direction: row;
}

.members-container{
    margin-left: 5px;
    color: #6D6D6D;
}

.group-desc{
    margin-top: 10px;
    max-width: 600px;
}

.visableOnMobile{
    display: none;
}

.mobile-only-body{
    margin: 20px 0 0 -10px;
}

@media (max-width: 999px){
    .visableOnPc{
        display: none;
    }
    .visableOnMobile{
        display: block;
    }
}

.statusDropdown{
    position: absolute;
    top: 25px;
    left: -7px;
    width: 80px;
    height: 30px;
    color: black;
    background-color: white;
    border: 1px solid #E5E5E5;
    border-radius: 14.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}