.form-control {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px; /* change from 20px*/
    font-weight: 700;
    color: #363636;
}
.form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: unset;
    border: none;
}
.account-tab {
    padding: 2em 0 2em;
}
.account-tab input.active {
    color: #35BC32;
}
.account-tab .col-12 {
    border-bottom: 2px solid #F8F8F8!important;
    margin-bottom: 10px;
}

.container {
    display: grid;
    grid-gap: 1px;
    grid-template-columns:  repeat(5, 150px);
    grid-template-rows: repeat(5, 100px);
   
  }

  .form-btn {
    text-align: right;
    box-shadow: 0px 0px 18px 0px #808080;
    padding: 15px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--white);
}

.mobileTitle{
    display: none;
    font-weight: bold;
}


@media (max-width: 768px){
    .container {
        display: grid;
        grid-gap: 1px;
        margin: 0 auto;
      }
    
    .mobileTitle{
        display: block;
    }
}

  .container-photo {
    grid-column: 2 / 4;
    align-items: end;
  
 }

  .container-details {
    grid-column: 4 / 7;
    justify-self: stretch;
  }

  .container-company {
    grid-column: 4 / 7;
    grid-row: 3 / -1;
    justify-self: stretch;
  }

.form-group{ 
    height: 100%;
   
  
}
.social-media-form{
    margin-top: 2rem;
   
}
/* .button { 
   position: absolute;
   font-weight: bold;
   bottom: 1rem;
   right: 2rem;
   border-radius: 8px;
} */

.text-personal{
    font-weight: bolder;
    top: 2rem;  
    align-items: initial;
}

.form-group-title{ 
    position: initial;
    font-weight: bolder;
    font-size: 1.5rem;
    padding: 1rem;
}

.icons{ 
    margin-left: 0.5rem;
    font-size: 1.5rem;
}

.label{ 
    margin-left: 0.5rem;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: transparent;
    color: #171717;
    font-weight: 500;
    border-bottom: 3px solid var(--danger);
}
.nav-tabs .nav-link {
    color: #B8B8B8;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent;
    cursor: pointer;
}
.nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover {
    border-bottom: 3px solid var(--danger);
}




/* .row{ 
   padding-top: 1%;
   padding-right: 10%;
} 

@media screen and (max-width: 960px) {
    /* .row{ 
        
        padding-left: 15%;
        padding-right: 15%;

    }
    .column{ 
        width: 100%;
        padding-top: 5%;
        display: flexbox;
        flex-direction: column;
        flex-basis: 100%;
    
    } */
    /* .button { 
        position: relative;
        font-weight: bold;
        bottom: 1rem;
        float: right;
        right: 15%;
        border-radius: 8px;
     }
}  */
#userList .table-view span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: bolder;
    padding-left: 45%;

} 


.table-view{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 10em 3em 9em 4em 19em 14em 19em;
    grid-gap: 30px;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 2px solid #EBEBEB;
    padding-bottom: 10px;
    padding-top: 15px;

}

.items{
    padding-top: 15px;
    margin-left: 3em;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 5em 7em 14em 13em 18em 2em;
    
}

.horizontal-border { 
    border-top: 2px solid #EBEBEB;
    padding-bottom: 15px;
    padding-top: 2rem;
}

.mobileSaveButton{
    display: none !important;
}

@media (max-width: 576px){
    .nav {
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    .nav-tabs .nav-link {
        white-space: nowrap;
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover {
        border-bottom: 4px solid var(--danger);
    }
}




@media (max-width: 768px){
    #userList .table-view {
        display: none;
   
    }
   
    .items{
        padding-top: 15px;
        margin-left: 1rem;
        display: block;
        grid-template-rows: 1fr;
        grid-template-columns: auto;
        align-items: center;
        border-bottom: 2px solid #EBEBEB;
        padding-bottom: 15px;
        padding-top: 15px;    
        
    }

    .invoice-name{ 
        font-size: 17px;
        font-weight: bolder;
    }
    .invoice-fee {

        margin-left: 2rem;
        font-size: 25px;
        font-weight: bolder;
  
    
    }

    .invoice-date {
        display: block; 
        position: relative;
    
    }

    .infoButton { 
        display: none;
    }
   
    .row-sec-col{
        padding-top: 10%;
    }

    .saveButton{
        display: none !important;
    }
    
    .mobileSaveButton{
        width: 100%;
        background-color: #D12229 !important;
        border: 0px !important;
        display: block !important;
        padding: 12px 0px !important;
        font-weight: bold !important;
    }
}
    