.totalContainer{
    padding: 20px;
}

.topGroupActionsContainer{
    display: flex;
    justify-content: space-between;
    margin: 10px 0 40px 0;
}

.filterLabel{
    width: 40%;
}

.filterInput{
    width: 85%;
    margin-left: 5px;
}

@media (max-width: 768px){
    .topGroupActionsContainer {
        flex-direction: column;
        justify-content: flex-start;
    }

    .filterLabel{
        width: 100%;
    }

    .addGroupButton{
        width: 120px;
        margin-bottom: 20px;
    }
}