.memberCard {
    margin       : 16px 0;
    padding      : 12px 18px;
    background   : #FFFFFF;
    border       : 1px solid #D8D8D8;
    box-shadow   : 0px 4px 18px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
}

.memberCardStatus {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    gap            : 10px;
    border-radius  : 40px;
    width          : 90px;
    height         : 30px;
    margin-bottom  : 12px;
}

.memberCardTop {
    display        : flex;
    justify-content: space-between;
    flex-wrap      : wrap;
}

.memberCardContent {
    display        : flex;
    justify-content: flex-start;
    flex-wrap      : wrap;
}

.memberCardActions {
    margin-top     : 20px;
    display        : flex;
    justify-content: flex-start;
    column-gap     : 10px;
}

.memberCardItem {
    margin-right  : 24px;
    display       : flex;
    flex-direction: row;
    column-gap    : 6px;
}

.availableTokens {
    font-size  : 18px;
    font-weight: 700;
}

.activitySection {
    margin-top: 40px;
}

.historyItem {
    display   : flex;
    column-gap: 12px;
    margin    : 16px 0px;
}


/* *************** ToolTip Related ****************** */
/* Tooltip container */
.tooltip {
    position   : relative;
    display    : inline-block;
    margin-top : -2px;
    margin-left: 10px;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility      : hidden;
    background-color: #4A4A4A;
    color           : #fff;
    text-align      : center;
    padding         : 5px;
    border-radius   : 4px;
    font-weight     : 300;
    font-size       : 14px;
    line-height     : 24px;

    width      : 240px;
    bottom     : 100%;
    left       : 50%;
    margin-left: -120px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index : 1;
}

.tooltip .tooltiptext::after {
    content     : " ";
    position    : absolute;
    top         : 100%;
    left        : 50%;
    margin-left : -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #4A4A4A transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}