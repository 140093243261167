.event-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top : -10px; */
  /* border-top  : 1px solid grey; */
}

.event-details-status {
  position: absolute;
  top: 8px;
  right: 16px;
  /* border: 1px solid;
    padding: 4px;
    border-radius: 8px; */
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
}

/* by: NL Started*/
.event-social-links {
  position: absolute;
  top: 12px;
  right: 40px;
}

.event-details-status-modified {
  position: absolute;
  top: 12px;
  left: 40px;
}

.boc-event-tag {
  width: 110px;
  height: 27px;
  border: 1px solid #ffffff;
  background-color: #d12229;
  color: #ffffff;
  padding: 0px 8px;
  margin-left: 16px;
  line-height: 29px;
  font-size: 18px;
  font-weight: 400;
}

.event-status-var {
  width: 84px;
  height: 29px;

  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  text-align: center;

  color: #ffffff;
  padding: 1px 26px;
  border-radius: 50px;
}

.black-status {
  background-color: #000000;
}

.yellow-status {
  background-color: #ffaa29;
}

.green-status {
  background: #459c00;
}

.boc-event-red {
  background-color: #d12229;
}

.non-boc-event-blue {
  background-color: #0181f5;
}
/* by: NL Finished*/
.event-details-header {
  /* background-color: #D12229; */
  color: #ffffff;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  width: 90%;
  max-width: 699px;
  padding: 20px 40px;
  cursor: pointer;
  position: relative;
}

.event-details-header-unselected {
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  width: 90%;
  max-width: 699px;
  padding: 20px 40px;
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
}

.event-details-summary {
  /* display         : block; */
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

.event-details-rsvp {
  display: flex;
  align-items: flex-end;
  /* flex           : 1; */
  justify-content: flex-end;
  width: 150px;
}

.event-details-response {
  display: flex;
  /* flex-direction: column; */
  align-self: flex-end;
  justify-content: flex-end;
  width: 150px;
}

.event-details-response-going {
  /* width: 70px; */
  color: #28a745;
}

.event-details-response-notgoing {
  /* width: 130px; */
  color: #d12229;
}

.event-details-response-pending {
  /* width: 130px; */
  color: #ff7f09;
}

.event-details-rsvp-input {
  /* background-color   : #D12229; */
  /* width           : fit-content; */
  color: #ffffff;
  padding: 5px 10px;
  border: 2px solid #ffffff;
  font-weight: bold;
  cursor: pointer;
}

.event-details-rsvp-input:hover,
.event-details-rsvp-input:focus {
  border: 2px solid #ffffff;
}

.event-details-schedule {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: #fafafa;
  border-radius: 20px;
  width: 90%;
  max-width: 699px;
  padding: 20px 40px;
  font-size: 16px;
}

.event-details-rsvplist-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 699px;
  margin-top: 20px;
}

a:hover {
  text-decoration: underline;
}

.column-rsvp {
  background-color: #fafafa;
  border-radius: 20px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width           : 30%; */
  width: 45%;
}

.column-rsvp-count {
  font-weight: bold;
  font-size: 24px;
}

.column-rsvp-label {
  font-size: 16px;
}

.admin-button {
  background-color: #fafafa;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: static;
  margin: 10px;
}

.admin-button:hover {
  background-color: #eeeeee;
  border-radius: 20px;
  border-color: grey;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: static;
  width: 150px;
  height: 28px;
  margin: 10px;
}

.admin-button-delete {
  background-color: #fafafa;
  border-radius: 20px;
  border-color: grey;
  color: #d12229;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: static;
  margin: 10px;
}

.admin-button-delete:hover {
  background-color: #d12229;
  border-radius: 20px;
  color: #fafafa;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: static;
  width: 150px;
  height: 28px;
  margin: 10px;
}

.event-edit-container {
  padding: 0px;
}

.event-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.custom-hr {
  padding: 5px;
  border-bottom: 0.1px dimgrey solid;
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 90%;
  max-width: 699px;
}

.toggle-button {
  display: inline-block;
  box-shadow: none;
}

.add-event {
  margin-left: 20px;
}

.form-btn {
  text-align: right;
  box-shadow: 0px 0px 18px 0px #808080;
  padding: 15px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--white);
}

.event-button {
  background-color: #d12229;
  padding: 14px;
  text-align: center;
  color: white;
  border-radius: 8px;
  font-weight: bold;
}

.event-card-flight-arrangger {
  margin: auto;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  margin-top: 14px;
}

.add-event-bottom {
  text-align: right;
  box-shadow: 0px 0px 18px 0px #808080;
  padding: 15px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--white);
  display: none;
}

@media (max-width: 780px) {
  .add-event {
    display: none !important;
  }
  .add-event-bottom {
    display: block;
  }
  .event-details-header-unselected {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .event-details-rsvp {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }

  .event-details-header {
    flex-direction: column;
  }

  .event-details-header-unselected {
    flex-direction: column;
  }

  .event-details-rsvplist-container {
    flex-direction: column;
  }

  .column-rsvp {
    width: 100%;
    /* margin-bottom: 20px; */
  }

  .event-edit {
    flex-direction: column;
  }

  .event-social-links {
    position: unset;
    display: block;
    margin-top: 30px;
  }

  .event-details-status-modified {
    position: unset;
    display: block;
  }

  .event-details-response {
  }
}

.noEventDetails {
  margin-top: 32px;
  text-align: center;
}

.mobileModalButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
