
.table-view { 
    background: '#f2f2f2'; 
 }
 
 .table-view-description{ 
     max-width: 1rem;
     min-width: 300px;
 }

 .event-details-wrapper{
     overflow-y: scroll;
     height: calc(100vh - 170px);
 }
 .event-details-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
 
 #userList .table-header span {
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
     font-weight: 700;
 }

 .table-header, .table-item {
    display: grid;
    grid-template-rows: 10px;
    grid-template-columns: 3em 20em 20em 10em ;
    grid-gap: 24px;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 2px solid #EBEBEB;
    padding-bottom: 30px;
    margin-bottom: 15px;
    width: unset;
    

}
.event-details-paid-input{
    background-color: white;
    width: fit-content;
    color: black;
    padding: 5px 10px;
    border:1px solid #535353;
    font-weight: bold;
    cursor: pointer;
}

#userList .table-header {
         display: none;
     }
 
 
.list-title {
         font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
         font-weight: 700;
         margin-left: 20%;
}
 
.list-detail{ 
        margin-top: 10px;
        font-weight: 700;
 
} 
     
.text-black { color: #000000; }
.list-description { color: #535353; margin-top: 5px }
.hide-mobile { display: none; }

     
.show-web{
    display: block;
    margin-left: 20%;
 
}

.list-photo { 
    float: left;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('https://orthosera.com/wp-content/uploads/2016/02/user-profile-placeholder.png');
    margin-left: 10px;
    height: 40px;
    width: 50px;
 
}

#userList .image {
    display: block;
    margin-top: -10px;
    margin-left: -10px;
}
 
@media (max-width: 768px){ 
    .table-header, .table-item {
        display: block;
        grid-template-rows: 1fr;
        grid-template-columns: auto;
        grid-gap: 24px;
        -webkit-align-items: center;
        align-items: center;
        border-bottom: 2px solid #EBEBEB;
        padding-bottom: 30px;
        margin-bottom: 15px;
        width: unset;
        max-width: 700px;
        
    }

    /* .button{ 
        display: block;
    } */
    
}