.aTag {
    text-decoration: none;
    color: rgb(97, 96, 96);
}

.aTag:hover {
    color: black;
    text-decoration: none;

}

.titleFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 7px;
}

.titleFlex p {
    font-size: 18px;
    color: black;
}

.member-management-container {
    position: relative;
}

.member-management-overlay-loader {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.204);
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}