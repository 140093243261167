.saveFooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(235, 235, 235);
    padding: 0.7rem;
}

.flightWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.guestListWrapper {
    width: 280px;
    position: fixed;
    left: 16px;
    top: 137px;
}

.guestList {
    height: calc(100vh - 322px);
    overflow-y: scroll;
}

.flightArrangerWrapper {
    width: calc(100vw - 310px);
    display: flex;
    flex-wrap: wrap;
}

.flightTitle {
    display: flex;
    justify-content: space-between;
}

.deleteFlight {
    color: red;
    cursor: pointer;
}

.flightUser {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

@media (max-width:768px) {
    .flightWrapper {
        flex-direction: column-reverse;
    }

    .guestListWrapper {
        position: unset;
        margin-left: 15px;
        width: 300px;
    }

    .guestList {
        display: none !important;
    }
}


.footerStyle{
    display: flex;
    justify-content: center;
    gap: 8px;
}