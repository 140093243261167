.home-container {
    width: 100%;
    height: auto;
    /* display: flex; */
    /* min-height: 100vh; */
    align-items: flex-start;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-container01 {
    width: 960px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-container02 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-around;
    background-color: #DCDCDC;
  }
  .home-container03 {
    width: 50px;
    display: flex;
    align-items: center;
    border:1px solid #000;
    border-width: 1px;
    flex-direction: column;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-container04 {
    width: 80px;
    display: flex;
    align-items: flex-start;
    border:1px solid #000;
    border-width: 0px;
    flex-direction: row;
    justify-content: center;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  .home-text1 {
    align-self: center;
  }
  .home-container05 {
    width: 140px;
    display: flex;
    align-items: flex-start;
    border:1px solid #000;
    border-width: 0px;
    flex-direction: row;
    justify-content: center;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  .home-container06 {
    flex: 1;
    display: flex;
    align-items: center;
    border:1px solid #000;
    border-width: 0px;
    flex-direction: column;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  .home-container07 {
    width: 150px;
    display: flex;
    align-items: center;
    border:1px solid #000;
    border-width: 0px;
    flex-direction: column;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  .home-container08 {
    width: 60px;
    display: flex;
    align-items: center;
    border:1px solid #000;
    border-width: 0px;
    flex-direction: column;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  .home-container09 {
    width: 150px;
    display: flex;
    align-items: center;
    border:1px solid #000;
    border-width: 0px;
    flex-direction: column;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  .home-container10 {
    width: 100px;
    display: flex;
    align-items: center;
    border:1px solid #000;
    border-width: 1px;
    flex-direction: column;
  }
  