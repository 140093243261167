/**
* IMPORTANT: INCLUDE .profile-table TO EVERY STYLE TO AVOID CSS CONFLICT FROM UserTable.css
**/

.input-group {
    margin-top: 3px;
}
.profile-table .row {
    margin: 0;
    border-bottom: 1px solid #E5E5E5;
}
.profile-table .col {
    padding: 15px;
}
.profile-table .label {
    margin-left: 0;
    margin-right: 5%;
}
.profile-table .action-web .label:not(.visible-mobile) {
    display: inline-block;
    margin-right: 20px;
}
.profile-table .profile-avatar {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}
.profile-table .web-action-button {
    margin-top: 15px;
    display: none;
    text-align: center;
}
.profile-table .action-btn {
    padding: 5px 15px;
    border-radius: 25px;
    border: 1px solid #E5E5E5;
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 5px;
}
.profile-table .visible-mobile, .profile-table .visible-tablet {
    display: none;
}
.profile-table .action-tablet {
    display: none;
}
.profile-table .action-btn:hover {
    cursor: pointer;
    background: #E5E5E5;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.description {
    color: #535353;
}

@media only screen and (max-width: 769px) and (min-width: 427px) {
    .profile-table .visible-tablet {
        display: block;
    }
    .profile-table .row-wrapper:hover .action-tablet { 
        display: block; 
        text-align: center;
    }
    .profile-table .row-wrapper:hover .description {
        display: none;
    }
}

@media only screen and (min-width: 769px) {
    .profile-table .row:hover .web-action-button {
        display: block;
    }
    .profile-table .row:hover .description {
        display: none;
    }
}

@media screen and (max-width: 768px){
    .profile-table .web-action-button, .profile-table .desc-wrapper { display: none;}
    .profile-table .row:first-child {
        border-bottom: 0;
    }
}

@media screen and (max-width: 427px) {
    /* .web-action-button { display: none!important;}
    .border-members-modal { 
        border: 1px solid lightgrey;
        border-radius: 10px;
        padding: 5px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        width: 95%; 
    }
    .profile-table .row {
        display: block;
    }
    .profile-table .col:first-child {
        padding-bottom: 0;
    } */
    .profile-table .hidden-mobile {
        display: none;
    }
    .profile-table .visible-mobile {
        display: block;
    }
    /* .profile-table .label:not(.hidden-mobile), .expiry-date:not(.hidden-mobile) {
        width: 50%;
        margin: 0;
        display: inline-block;
    } */
}