.profile-photo-wrapper {
    margin-top: 2em;
    margin-bottom: 20px;
}
.profile-photo-wrapper .d-flex {
    /* align-items: stretch; */
    justify-content: center;
}
    
/* .profile-photo-wrapper .d-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    }  */
.profile-photo-wrapper .d-flex .flex-shrink-0 {
    padding-left: 15px;
    padding-right: 15px;
}

.profile-wrapper{ 
        display:flex; 
        flex-direction:row;
        width: max-content;
        margin-bottom: 40px;
}

.imageCategory{
    display: flex;
    justify-content: center;
}

.emptyImage{
    background-color: lightgrey;
    text-align: center;
    padding: 30px;
    border-radius: 8px;
    margin: 20px 0px;
    color: white;
    font-weight: bold;
}

.imageDescription{
   margin-left: 24px;
   align-content:center;
}

@media (max-width: 576px){
    .profile-photo-wrapper {
        margin-bottom: 0;
    }
    .profile-photo-wrapper .d-flex {
        display: block!important;
        text-align: center;
    }
    .form-group {
        text-align: left!important;
    }

}
.imageContainer{
    margin-top: 10px;
    width: 160px;
    height: 160px;
    cursor: pointer;
}
.image{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
}
.companyLogo .image, .companyLogo .imageContainer {
    width: 140px;
    height: 130px;
    border-radius: unset;
    object-fit: contain;
}

@media (max-width:780px){
    .imageCategory{
      flex-direction: column;
    }

    .imageDescription{
        margin-left: 0px;
    }

    .imageContainer{
    margin: auto;
    }
}