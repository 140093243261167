.switch {
    position     : relative;
    display      : inline-block;
    width        : 30px;
    height       : 17px;
    margin-top   : 3px;
    margin-bottom: 0px;
}

.switch input {
    opacity: 0;
    width  : 0;
    height : 0;
}

.slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    background-color  : #ccc;
    -webkit-transition: .4s;
    transition        : .4s;
}

.slider:before {
    position          : absolute;
    content           : "";
    height            : 13px;
    width             : 13px;
    left              : 2px;
    bottom            : 2px;
    background-color  : white;
    -webkit-transition: .4s;
    transition        : .4s;
}

/* input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
} */

input:checked+.slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform    : translateX(13px);
    transform        : translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 17px;
}

.slider.round:before {
    border-radius: 50%;
}