.content-wrapper {
    width: 100%;
    height: auto;
    bottom: 0px;
    top: 0px;
    left: 0;
    right: 0;
    padding: 10px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-init {
    text-align: center;
}
.text {
    font-weight: 500;
    max-width: 500px;
}
.floating-button{
    display: none;
}

@media (max-width: 768px){

    .floating-button{
        display: block;
        position: fixed;
        background-color: #D12229
        ;
        border-radius: 50%;
        height: 50px;
        width:50px;
        right: 20px;
        bottom: 88px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    }

    .floating-button span {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        color: white;
        padding-left: 5px;
        /* font-size: 3em; */
        transition: 0.3s ease-in-out;
    }

    .floating-button ul{ 
        position: block;
        position: fixed;
        width: 100%;
        /* min-height: 490px; */
        height: 85%;
        position: fixed;
        background-color: #D12229;
        right: 5px;
        bottom: 5px;
    }

} 