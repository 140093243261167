.profileViewWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}
.profileSectionWrapper{
    display: flex;
    width: max-content;
}

.profileSection{
    display:flex; 
    flex-direction:row;
    width: max-content;
    margin-bottom: 40px;
}

.company-photo-img{
    width: 80%;
}

.company-photo {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 250px;
}

.profile-photo{
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileDetails{
    width: 500px;
}

.profileIcons{
    margin: 0px;
}

.profileDivider {
    display: none;
}

.aa22 {
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    width: 90%
}

.profileAwardsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.singleAward {
    margin-right: 60px;
}
.singleAward>span{
    margin-left: 10px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.reviewDetail{
   display: flex;
   margin: 20px auto;
}

.profileImg{
    width:250px;
    height: fit-content;
    text-align:center;
}

.social{
    display: flex;
    
}

.firstDivider{
    margin: 40px 0px;
    display: none;
}

.reviewDetailName{
    font-weight: bold;
     font-size: 24px; 
}

.reviewDetailCompany{
    display: flex;
    margin: 20px auto;
 }

 .award{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
 }

 .visitorViewMain{
    margin:20px auto;
    width:max-content;
 }

@media (max-width: 768px){
    .profileSection{
        flex-direction:column;
        justify-content: center;
        align-items: center;
    }
    .profileDetails{
        width: 90vw;
    }
    .company-photo {
        margin-bottom: 1rem;
        justify-content: center;
        margin: auto;
    }
    .profileDivider {
        display: block;
    }

    .singleAward {
        margin: 0 25px 20px 0;
    }
    .aa22 {
        margin-bottom: 0px;
    }

    .profileAwardsContainer {
        margin-top: 0px;
    }
    
    .awardsTitle2 {
        align-self: flex-start;
        margin-bottom: 30px;
    }

    .reviewDetail{
        flex-direction: column;
        text-align: center;
    }

    .reviewDetailName{
        padding-top: 20px;
    }

    .social{
        justify-content: space-around;
    }
    .profileImg{
        margin: auto;
    }
    .firstDivider{
        display: block;
    }

    .reviewDetailCompany{
        flex-direction: column;
    }
    .visitorViewMain{
        width: 100%;
    }
    
    
}