.search-group {
    position: relative;
    margin-top: 10px;
}
.search-group .input-group-prepend {
    position: absolute;
    left: 0;
    z-index: 4;
    bottom: 0;
    top: 0;
}
.search-group .input-group-text {
    background: none;
    border: 0;
}
.search-group .search {
    padding-left: 39px;
    border: 3px solid #696d70;
}
.search-group input.search.form-control::placeholder {
    color: #696d70;
}
.search-group .search:focus {
    box-shadow: 0 0 0 0 #495057;
    border-color: #495057;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.search-group input.search:focus.form-control::placeholder {
    color: inherit;
}