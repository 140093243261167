.page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.page-container01 {
  width: 100%;
  height: 153px;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.page-container02 {
  width: 50%;
  height: 124px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.page-image {
  width: 100px;
  height: auto;
  align-self: flex-start;
  object-fit: cover;
}
.page-container03 {
  width: 50%;
  height: 124px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.page-text {
  font-size: 27px;
  align-self: flex-end;
  font-weight: 700;
}
.page-container04 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5%;
}
.page-container05 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-text01 {
  display: inline;
  position: static;
}
.page-text02 {
  font-weight: 700;
}
.page-text03 {
  font-weight: 700;
}
.page-container06 {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-container07 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 20px !important;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.page-container08 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.page-text06 {
  padding: 20px;
  font-size: 12px !important;
}
.page-text08 {
  font-weight: 700;
}
.page-text09 {
  font-weight: 700;
}
.page-container09 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 20px !important;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.page-container10 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.page-text11 {
  font-size: 12px !important;
}
.page-container11 {
  width: 250px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 2px solid black;
}
.page-container12 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.page-text12 {
  font-weight: 700;
}
.page-container13 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.page-text13 {
  font-size: 14px;
}
.page-text14 {
  font-weight: 700;
}
.page-container14 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.page-container15 {
  width: 33.3%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-text15 {
  font-size: 14px;
}
.page-container16 {
  width: 33.3%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-text16 {
  font-size: 14px;
}
.page-container17 {
  width: 33.3%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-text17 {
  font-size: 14px;
}

.summary-table {
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 12px;
}

// for old version
.page-image-old {
  width: 140px;
  height: 100%;
  align-self: flex-start;
  object-fit: cover;
}

.page-text-old {
  font-size: 40px;
  align-self: flex-end;
  font-weight: 700;
}
.page-container04-old {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.page-container06-old {
  width: 50%;
  height: 54px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.page-container11-old {
  width: 200px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom-width: 1px;
}

.summary-table-old {
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}
