.btn {
    border-radius: 10px;
}
.btn-danger {
    background-color: #F32828;
    border-color: #F32828;
}
.navbar-light {
    background-color: var(--white);
}
.navbar-light .navbar-toggler {
    border-color: transparent;
}
.mobile-menu ul li {
    padding-top: 25px;
}
.mobile-menu ul li a {
    color: #666666;
}

.nav-link.active {
    background-color: transparent;
    border-color: transparent;
    color: #171717;
    font-weight: 500;
    border-bottom: 3px solid var(--danger);
}

@media screen and (max-width: 768px){
    .navbar {
        display: none;
    }
}